<template>
  <div>
    <div v-if="showPage">
      <div class="homepage-header" id="home-index">
        <div class="header-blur"></div>
        <div class="tabs-wrap">
          <div class="tabs-wrap-blur"></div>
          <div class="tabs-wrap-inner">
            <div class="page-intro">
              <h1
                style="
                  padding-top: 10px;
                  padding-bottom: 10px;
                  color: #ffffff;
                  font-weight: 500 !important;
                "
              >
                来客了
              </h1>
            </div>
            <div class="page-header-inner clearfix" id="menu-list">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="首页" name="home-index"></el-tab-pane>
                <el-tab-pane label="产品功能" name="product-core"></el-tab-pane>
                <el-tab-pane
                  label="适用行业"
                  name="solution-case"
                ></el-tab-pane>
                <el-tab-pane label="关于我们" name="about-us"></el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>

        <div class="header-content clearfix">
          <div class="header-content-left pull-left">
            <h1 style="color: #ffffff; font-size: 30px" class="zh-name">
              触达抖音海量用户，让客来
            </h1>
            <h2 style="color: #ffffff; font-weight: 100" class="name-intro">
              提供一站式的短视频内容创作、投放、分析等专业服务。让每一次活动推广实现数据闭环，实现活动精准触达目标客群。
            </h2>
            <a class="use-now-btn" href="/tenant">立即使用</a>
          </div>
          <div class="header-content-right pull-right"></div>
        </div>
      </div>

      <div class="homepage-block-product">
        <div style="height: 650px">
          <div class="product-content-left pull-left">
            <h1 class="zh-name-title">为什么我们可以做到？</h1>
            <h2 class="name-intro">我们能制作好的内容</h2>
            <h2 class="name-content">
              专业策划团队会结合您的经营情况，深入现场调研，经过专业策划拍摄出觉有感染力的抖音小视频。
            </h2>
            <h2 class="name-intro">触达抖音精准用户</h2>
            <h2 class="name-content">
              消费者线下通过抖音二维码线上参与活动，一键分享制作好的视频，精准触达“他”的圈子，通过GPS位置精准触发附近用户。
            </h2>
            <h2 class="name-intro">数据闭环</h2>
            <h2 class="name-content">
              消费者参与活动，效果可监控，结果可评估，让您的活动真实有效。
            </h2>
          </div>
          <div class="product-content-right pull-right"></div>
        </div>
        <div style="height: 400px">
          <h2 class="homepage-sub-title">专业的抖音视频策划团队</h2>
          <div class="solution-title-panel center">
            <div class="left-title-content pull-left">
              <h1 class="num-text">100+</h1>
              <h2 class="solution-content">我们拥有专业抖音视频策划人员</h2>
            </div>
            <div class="right-title-content pull-right"></div>
          </div>
        </div>
      </div>

      <div class="homepage-block-solution" id="product-core">
        <h2 class="homepage-sub-title">产品功能</h2>
        <div class="product-solution-list clearfix">
          <div class="product-solution">
            <div class="solution-icon solution-1"></div>
            <h2 class="item-header">多账户管理</h2>
            <p class="item-desc">
              支持管理多个帐号抖音号，让您的团队协作更简单高效
            </p>
          </div>
          <div class="product-solution">
            <div class="solution-icon solution-2"></div>
            <h2 class="item-header">发布活动</h2>
            <p class="item-desc">
              通过后台发布活动，支持可配置话题，设置奖项规则，自主掌控活动进程
            </p>
          </div>
          <div class="product-solution">
            <div class="solution-icon solution-3"></div>
            <h2 class="item-header">一键发布</h2>
            <p class="item-desc">
              一键将短视频发布到抖音、头条、火山小视频等多个平台，让您的工作更高效
            </p>
          </div>
          <div class="product-solution">
            <div class="solution-icon solution-4"></div>
            <h2 class="item-header">数据分析</h2>
            <p class="item-desc">
              全方位的视频数据分析，用户画像，深度了解潜在用户，指导经营决策
            </p>
          </div>
          <div class="product-solution">
            <div class="solution-icon solution-5"></div>
            <h2 class="item-header">评论整理</h2>
            <p class="item-desc">
              自动归集您关心的评论，让您能清楚的看见客户的心声
            </p>
          </div>
          <div class="product-solution">
            <div class="solution-icon solution-6"></div>
            <h2 class="item-header">统计报表</h2>
            <p class="item-desc">
              每日涨粉、转发、播放、转化、复购、活跃度等等
            </p>
          </div>
        </div>
      </div>
      <div class="homepage-block-feature" id="solution-case">
        <h2 class="homepage-sub-title">适用行业</h2>
        <div class="product-feature-list clearfix">
          <div class="product-feature">
            <div class="feature-icon feature-1"></div>
            <h2 class="item-feature-header">餐饮、娱乐行业</h2>
            <p class="item-feature-desc">饭店、酒吧、KTV等</p>
          </div>
          <div class="product-feature">
            <div class="feature-icon feature-2"></div>
            <h2 class="item-feature-header">旅游行业</h2>
            <p class="item-feature-desc">旅游景点、公园等</p>
          </div>
          <div class="product-feature">
            <div class="feature-icon feature-3"></div>
            <h2 class="item-feature-header">教育行业</h2>
            <p class="item-feature-desc">学校、培训机构等</p>
          </div>
          <div class="product-feature">
            <div class="feature-icon feature-4"></div>
            <h2 class="item-feature-header">医美、婚庆行业</h2>
            <p class="item-feature-desc">美容、婚纱摄影等</p>
          </div>
          <div class="product-feature">
            <div class="feature-icon feature-5"></div>
            <h2 class="item-feature-header">健身行业</h2>
            <p class="item-feature-desc">篮球馆、健身馆等</p>
          </div>
          <div class="product-feature">
            <div class="feature-icon feature-6"></div>
            <h2 class="item-feature-header">家居建材行业</h2>
            <p class="item-feature-desc">装修、家具、建材等</p>
          </div>
        </div>
      </div>
      <div class="homepage-block-about" id="about-us">
        <h2 class="c-white">关于我们</h2>
        <h2 class="h2-text">我们一直致力于帮助伙伴触达消费者</h2>
        <div class="mobile-bg">
          <h2 class="mobile-text">193&nbsp;0287&nbsp;1101</h2>
          <div style="width: 120px; padding-right: 10px" />
          <a class="use-now-btn" href="/tenant">联系我们</a>
        </div>
        <div class="address">
          <h2 class="address-text">中国·四川·成都</h2>
        </div>
      </div>

      <div class="homepage-block-footer">
        <p class="footer-copyright">
          © {{hostConfig.year || '2021'}} {{hostConfig.comp_name || '厚锋科技'}} 版权所有&emsp;<a
            style="color: #fff"
            :href="hostConfig.icp_server || 'https://beian.miit.gov.cn/'"
            target="_blank"
            >{{ hostConfig.icp_no }}</a
          >
        </p>

        <!-- https://beian.miit.gov.cn/ https://beian.miit.gov.cn/#/Integrated/index -->

      </div>
    </div>
  </div>
</template>

<script>
import { GET_DOMAIN } from "@/utils";
export default {
  name: "home",
  created() {
    
  },
  mounted() {
    console.log(this.$route.query);

    // console.log(this.$router);
    // console.log(window.location.hostname);
    if (window.location.hostname.indexOf("wxmp.cys.afterwind.cn") !== -1) {
      // window.location.href = "";
      location.href = this.$route.query.url;

      return;
    } else if (window.location.hostname.indexOf("qr.wx.afterwind.cn") !== -1) {
      window.open(window.location.href);
      return;
    }
    // if (this.$rout)

    this.showPage = true;

    document.title = "";//"来客了 - 致力于帮客户更快、更好触达消费者";

    window.addEventListener("scroll", this.handleScroll, true);
    // console.log(this.hostConfigs['www.wandou.work']);
    this.server = GET_DOMAIN();
    console.log(this.server);

    this.$get("icp/srv/config", { host: this.server }, (res) => {
      // console.log(res);
      this.hostConfig = res.data || {};
      document.title = this.hostConfig.title || '';
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      activeName: "home-index",
      hostConfigs: {
        "www.wandou.work": "蜀ICP备17018851号-3",
        "www.wandoujob.cn": "蜀ICP备17018851号-4",
        "www.afterwind.cn": "蜀ICP备17018851号-2",
        "www.small-best.com": "蜀ICP备17018851号-1",
        "www.sszl87.cn": "",
        "sszl87.cn": "",
        "sszl187.cn": "",
        "sszl178.cn": "",
      },
      hostConfig: {},
      server: null,
      showPage: false
    };
  },
  methods: {
    handleClick() {
      var elmnt = document.getElementById(this.activeName);
      elmnt.scrollIntoView();
    },
    checkContain(id) {
      var el = document.getElementById(id);
      // const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const top = el.getBoundingClientRect() && el.getBoundingClientRect().top;
      return top <= 0;
    },
    handleScroll() {
      let arr = ["home-index", "product-core", "solution-case", "about-us"];
      for (var i = 0; i < arr.length; ++i) {
        if (this.checkContain(arr[i])) {
          this.activeName = arr[i];
        }
      }
    },
  },
};
</script>

<style scoped>
</style>